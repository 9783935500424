import React from "react";
import "./Tentang.css";
import Visi from "../Img/vision.webp";
import Mission from "../Img/mission.webp";
import Values from "../Img/values.webp";
import mantul from "../Img/mantul.webp";
import angsa from "../Img/angsa.webp";
import kunci from "../Img/kunci.webp";
import m777 from "../Img/777.webp";
import HK from "../Img/HK.webp";
import logo from "../Img/logo200.webp";
import kacangkupas from "../Img/kacangkupastangan.webp";
import video from "../Img/video2.mp4";
import { Helmet } from "react-helmet-async";
function TentangEng() {
  return (
    <div className="Tentang">
      <Helmet>
        <title>About PT Agri Tunas Makmur</title>
        <meta
          name="description"
          content="PT Agri Tunas Makmur is a company engaged in the sales and purchases
          of agricultural products. PT Agri Tunas Makmur was established in
          2015 in the city of Medan, North Sumatra. We import our products
          from more than 15 countries and sell them on a large scale for
          buyers in the Indonesian market."
        />
        <link
          rel="canonical"
          href="https://www.agritunasmakmur.com/eng/about-us/"
        />
      </Helmet>
      <div className="Tentang-L">
        <img src={logo} alt="" />
        <h2>PT Agri Tunas Makmur</h2>
      </div>
      <div className="Tentang-H">
        <div className="Tentang-S">
          <h1>Sejarah Kami</h1>
          <p>
            PT Agri Tunas Makmur is a company specializing in the sale of
            agricultural products. We have been established since 2015 in the
            city of Medan, North Sumatra, and have successfully imported
            agricultural materials from more than 15 countries to be sold in the
            Indonesian market.
            <br />
            <br />
            With good corporate governance and the trust of our customers, Agri
            Tunas Makmur has grown rapidly from a small store in the market in
            the 90s. We continue to strive to provide the best for our
            customers.
          </p>
        </div>
        <div className="Tentang-banner">
          <img src={kacangkupas} alt="kacang kupas" />
          <video src={video} autoPlay loop muted />
        </div>
        <div className="Tentang-VMV">
          <div className="Tentang-Visi">
            <img src={Visi} alt="" />
            <h2>Vision</h2>
            <p>
              To be an import-export company with a reputation for excellence
              and reliable for our buyers.
            </p>
          </div>
          <div className="Tentang-Misi">
            <img src={Mission} alt="" />

            <h2>Mision</h2>
            <p>To provide the highest quality of products for our buyers.</p>
          </div>
          <div className="Tentang-Value">
            <img src={Values} alt="" />
            <h2>Core Value</h2>
            <p>The values we uphold are Honesty, Trust, and Integrity.</p>
          </div>
        </div>
        <div className="Tentang-M">
          <h2>Our Brands</h2>
          <div className="Tentang-Img">
            <img src={kunci} alt="" />
            <img src={angsa} alt="" />
            <img src={mantul} alt="" />
            <img src={m777} alt="" />
            <img src={HK} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default TentangEng;
