import { SET_PRODUCTS, SEARCH_PRODUCT } from "../Contants/actionTypes";
export const setProducts = (products) => {
  return {
    type: SET_PRODUCTS,
    products,
  };
};
export const searchProduct = (query) =>{
    return {
        type: SEARCH_PRODUCT,
        query,
    }
}