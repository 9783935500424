import React, { useEffect, useState } from "react";
import "./Home.css";
import Banner from "../Banner/Banner";
import CS from "../Img/customer.webp";
import Imp from "../Img/import.webp";
import logo2 from "../Img/logonobg2.webp";
import quality from "../Img/quality.webp";
import pengiriman from "../Img/delivery.webp";
import video from "../Img/video.mp4";
import { Button } from "react-bootstrap";
import { MoonLoader } from "react-spinners";
function HomeEng() {
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
  }, []);
  return (
    <div>
      <div className={loading ? "PageLoading" : "PageNone"}>
        <MoonLoader color="#007057" loading={loading} />
      </div>
      <div className={loading ? "PageNone" : "Page"}>
        <Banner />
        <div className="Home">
          <div className="Home-A">
            <div className="Home-AB">
              <div className="Home-A1">
                <h1>"PT Agri Tunas Makmur</h1>
                <p>
                  is a company engaged in the sales and purchases of
                  agricultural products. We were established in 2015 in the city
                  of Medan, North Sumatra. Our company imports agricultural
                  products from more than 15 countries and sold them on a large
                  scale to buyers in the Indonesian market."
                </p>
              </div>
              <div className="Home-A2">
                <div className="Home-vcontainer">
                  <video
                    className="Home-video"
                    src={video}
                    width="640"
                    height="360"
                    muted
                    loop
                    autoPlay
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="Home-SwiperC">
            <div className="Home-AB">
              <div className="Home-A3">
                <img src={logo2} alt="agritunasmakmur" />
                <h2>Agri Tunas Makmur</h2>
                <p>
                  "PT Agri Tunas Makmur is a trusted solution to meet all your
                  business needs, located in Medan, North Sumatra."
                </p>
              </div>
              <div className="Home-A3">
                <img src={quality} alt="quality" />
                <h2>Quality</h2>
                <p>
                  "Thousands of the best fresh products that can be customized
                  to your business needs."
                </p>
              </div>
              <div className="Home-A3">
                <img src={pengiriman} alt="pengiriman" />
                <h2>Logistics</h2>
                <p>
                  "Supported by our logistic and storage ecosystem that is
                  optimal and perfect for storing our products."
                </p>
              </div>
            </div>
          </div>
          <div className="Home-B">
            <div className="Home-B2">
              <div className="Home-B2W">
                <h3>Products</h3>
                <img src={Imp} alt="" />
                <p>
                  We have many kinds of products imported from more than 15
                  country.
                </p>
                <Button
                  className="Home-BButton"
                  href="/eng/product-list"
                  variant="success"
                >
                  Our Products
                </Button>
              </div>
            </div>
            <div className="Home-B2">
              <div className="Home-B2W">
                <h3>Customer Care</h3>
                <img src={CS} alt="" onLoad={() => setLoading(false)} />
                <p>
                  If you have any questions, please contact us directly here.
                </p>
                <Button
                  className="Home-BButton"
                  href="/eng/contact-us"
                  variant="success"
                >
                  Contact Us
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HomeEng;
