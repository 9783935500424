import { FiPhoneCall, FiMail, FiInstagram } from "react-icons/fi";
import { GoLocation } from "react-icons/go";
import { BiTimeFive } from "react-icons/bi";
import { BsWhatsapp } from "react-icons/bs";
import "./Footer.css";
import React from "react";
import product_list from "../Product/ProductArray.jsx";
function Footer() {
  const d = new Date();
  let year = d.getFullYear();
  const listProducts = product_list.map((product) => (
    <li key={product.id}>
      <a
        href={`/produk/${product.nama_produk
          .toLowerCase()
          .split(" ")
          .join("-")}`}
        style={{ textDecoration: "none" }}
      >
        {product.nama_produk}
      </a>
    </li>
  ));
  return (
    <div className="Footer">
      <div className="Footer-h">
        <div className="Footer-info">
          <h3>Agri Tunas Makmur</h3>
          <p>
            PT Agri Tunas Makmur merupakan perusahaan yang bergerak dalam bidang
            pertanian. Perusahaan kami mengimport bahan pertanian lebih dari 15
            negara dan menjualnya dalam skala besar.
          </p>
        </div>
        <div className="Footer-product">
          <h3>Produk</h3>
          <ul>{listProducts}</ul>
        </div>
        <div className="Footer-contact">
          <h3>Hubungi Kami</h3>
          <a
            href="tel:0614152898"
            style={{ textDecoration: "none" }}
            target="_blank"
            rel="noreferrer"
          >
            <p>
              <FiPhoneCall /> 061 415 2898
            </p>
          </a>
          <a
            href="tel:081260869388"
            style={{ textDecoration: "none" }}
            target="_blank"
            rel="noreferrer"
          >
            <p>
              <FiPhoneCall /> +62 853 7342 4998
            </p>
          </a>

          <a
            style={{ textDecoration: "none" }}
            href="https://goo.gl/maps/67FfykK9BiLHr3sQ6"
            target="_blank"
            rel="noreferrer"
          >
            <p>
              <GoLocation /> Jl. Candi Prambanan No.2BB Medan
            </p>
          </a>

          <p>
            <BiTimeFive /> 09:00 – 17:00 (Senin – Sabtu)
          </p>
          <a
            style={{ textDecoration: "none" }}
            href="https://www.instagram.com/agritunasmakmur/"
            target="_blank"
            rel="noreferrer"
          >
            <p>
              <FiInstagram /> agritunasmakmur
            </p>
          </a>
          <a href="/hubungi-kami" style={{ textDecoration: "none" }}>
            <p>
              <FiMail /> agritunasmakmur@gmail.com
            </p>
          </a>
        </div>
        <div className="Footer-fastresp">
          <h3>Respon Cepat</h3>
          <p>
            <a
              style={{ textDecoration: "none" }}
              href="https://wa.me/6285373424998?text=Saya%20tertarik%20dengan%20produk%20Anda%20yang%20dijual"
              target="_blank"
              rel="noreferrer"
            >
              <BsWhatsapp /> +62 853 7342 4998
            </a>
          </p>
        </div>
      </div>
      <div className="Footer-f">
        Copyright © {year} Agri Tunas Makmur. All Rights Reserved.
      </div>
    </div>
  );
}

export default Footer;
