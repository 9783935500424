import React from "react";
import "./Banner.css";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/autoplay";
import "swiper/css/effect-fade";
import { Autoplay, EffectFade } from "swiper";
import logo from "../Img/logo200.webp";
const Banner = () => {
  return (
    <div className="Banner-h">
      <Swiper
        modules={[EffectFade, Autoplay]}
        effect={"fade"}
        loop={true}
        className="Banner-splide"
        autoplay={{
          delay: 5000,
          disableOnInteraction: true,
        }}
      >
        <SwiperSlide className="Banner-slide">
          <div className="Banner-slide1">
            <img src={logo} alt="" />
          </div>
        </SwiperSlide>
        <SwiperSlide className="Banner-slide">
          <div className="Banner-slide3">
            <img src={logo} alt="" />
          </div>
        </SwiperSlide>
        <SwiperSlide className="Banner-slide">
          <div className="Banner-slide2">
            <img src={logo} alt="" />
          </div>
        </SwiperSlide>
      </Swiper>
    </div>
  );
};

export default Banner;
