import React from "react";
import NoFound from "../Img/404.webp";
import './NotFound.css'
import './NotFound.css'
const NotFound = () => {
  return (
    <div className="NotFound">
      <img src={NoFound} alt="" />
    </div>
  );
};

export default NotFound;
