import React, { useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import product_list from "./ProductArray";
import "./Products.css";
import Empty from "../Img/empty.webp";
import { Helmet } from "react-helmet-async";
import logo2 from "../Img/logonobg2.webp";
import { Splide, SplideSlide } from "@splidejs/react-splide";
function Products() {
  let params = useParams();
  let product = params.id
    .split("-")
    .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
    .join(" ");
  const listProducts = product_list.map((product) => (
    <li key={product.id}>
      <Link
        to={`/produk/${product.nama_produk.toLowerCase().split(" ").join("-")}`}
        style={{ textDecoration: "none" }}
      >
        <p className="underline">{product.nama_produk}</p>
      </Link>
    </li>
  ));
  const filteredList = product_list.filter(
    (item) => item.nama_produk.toLowerCase().split(" ").join("-") === params.id
  );
  const listProduct = product_list.map((product) => (
    <SplideSlide
      key={product.id}
      onClick={() => {
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
      }}
    >
      <Link
        to={`/produk/${product.nama_produk.toLowerCase().split(" ").join("-")}`}
        style={{ textDecoration: "none", color: "#191919" }}
      >
        <div className="Products-card">
          <div className="Products-card-img">
            <img src={product.foto} alt={"PT Agri Tunas Makmur - Supplier " + product.nama_produk + " Medan"} id={product.id} />
          </div>
          <div className="Products-card-header">
            <h2>{product.nama_produk}</h2>
          </div>
        </div>
      </Link>
    </SplideSlide>
  ));
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  return (
    <div className="Products">
      <Helmet>
        <title>
          Jual {product} di Medan - Distributor {product} Medan Sumatera
          Utara - PT Agri Tunas Makmur
        </title>
        <meta
          name="description"
          content={
            "Sedia " +
            product +
            " berkualitas dan bahan pertanian lainnya yang di impor lebih dari 15 negara."
          }
        />
        <link
          rel="canonical"
          href={"https://www.agritunasmakmur.com/produk/" + params.id + "/"}
        />
        <meta
          property="og:url"
          content={"https://www.agritunasmakmur.com/produk/" + params.id + "/"}
        />
        <meta
          property="twitter:url"
          content={"https://www.agritunasmakmur.com/produk/" + params.id + "/"}
        />
        <meta
          property="og:title"
          content={
            "Jual " +
            product +
            " di Medan" +
            " - Distributor " +
            product +
            " Medan Sumatera Utara - PT Agri Tunas Makmur"
          }
        />
        <meta
          property="og:description"
          content={
            "Sedia " +
            product +
            " berkualitas dan bahan pertanian lainnya yang di impor lebih dari 15 negara."
          }
        />
        <meta
          property="og:image"
          content="https://www.agritunasmakmur.com/static/media/banner2.9f9002cbf5b8524ebd76.webp"
        />
        <meta
          property="twitter:title"
          content={
            "Jual " +
            product +
            " di Medan" +
            " - Distributor " +
            product +
            " Medan Sumatera Utara - PT Agri Tunas Makmur"
          }
        />
        <meta
          property="twitter:description"
          content={
            "Sedia " +
            product +
            " berkualitas dan bahan pertanian lainnya yang di impor lebih dari 15 negara."
          }
        />
        <meta
          property="twitter:image"
          content="https://www.agritunasmakmur.com/static/media/banner2.9f9002cbf5b8524ebd76.webp"
        />
      </Helmet>
      <div className="Products-logo">
        <img src={logo2} alt="PT Agri Tunas Makmur" />{" "}
        <h2>PT Agri Tunas Makmur</h2>
      </div>
      <div className="Products-container">
        <div className="Products-left">
          <div className="Products-L">
            <h3>Produk Lainnya</h3>
            <ul>{listProducts}</ul>
          </div>
        </div>
        <div className="Products-right">
          {filteredList.length ? (
            filteredList.map((data) => {
              return (
                <div className="Products-Details" key={data.id}>
                  <div>
                    <h1>{data.nama_produk}</h1>
                    <img src={data.foto} alt={"PT Agri Tunas Makmur - Supplier " + data.nama_produk + " Medan"} id={data.id} />
                    <table>
                      <tbody>
                        <tr>
                          <td>Ukuran</td>
                          <td>{data.ukuran}</td>
                        </tr>
                        <tr>
                          <td>Berat</td>
                          <td>{data.berat}</td>
                        </tr>
                        <tr>
                          <td>Bungkus</td>
                          <td>{data.bungkusan}</td>
                        </tr>
                        <tr>
                          <td>Asal</td>
                          <td>{data.asal}</td>
                        </tr>
                        <tr>
                          <td>Merek</td>
                          <td>{data.merek}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              );
            })
          ) : (
            <div className="Product-Empty">
              <span>Produk tidak ditemukan!</span>
              <img src={Empty} alt="" />
              <a
                style={{ textDecoration: "none" }}
                href="http://www.freepik.com"
              >
                Designed by slidesgo / Freepik
              </a>
              <br />
            </div>
          )}
        </div>
      </div>
      <div className="Products-swiper">
        <h2>Produk Lainnya</h2>
        <Splide
          options={{
            perPage: 6,
            rewind: true,
            gap: "0",
            autoWidth: true,
            height: "100%",
            breakpoints: {
              640: {
                perPage: 3,
                arrows: false,
              },
            },
          }}
        >
          {listProduct}
        </Splide>
      </div>
    </div>
  );
}

export default Products;
