import React from "react";
import FooterEng from "../Footer/FooterEng";
import NavigatorEng from "../Navbar/NavigatorEng";
import { Routes, Route } from "react-router-dom";
import ContactEng from "../Contact/ContactEng";
import ProductListEng from "../Product/ProductListEng";
import NotFound from "./NotFound";
import ProductsEng from "../Product/ProductsEng";
import TentangEng from "../Tentang/TentangEng";
import HomeEng from "./HomeEng";
import { motion } from "framer-motion";
const PageEng = () => {
  return (
    <div>
      <NavigatorEng />
      <motion.div
        animate={{ opacity: 1 }}
        initial={{ opacity: 0 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.4 }}
      >
        <Routes>
          <Route path="/" element={<HomeEng />} />
          <Route path="contact-us" element={<ContactEng />} />
          <Route path="product-list" element={<ProductListEng />} />
          <Route path="/products/:id" element={<ProductsEng />} />
          <Route path="/about-us" element={<TentangEng />} />
          <Route path="/*" element={<NotFound />} />
        </Routes>
      </motion.div>
      <FooterEng />
    </div>
  );
};

export default PageEng;
