const product_list = [
  {
    id: 1,
    nama_produk: "Kacang Tanah",
    product_name: "Peanuts",
    ukuran: "80/90 & 50/60",
    size: "80/90 & 50/60",
    berat: "50kg",
    bungkusan: "Kain Goni",
    packaging: "Burlap Bag",
    asal: "India, Africa",
    merek: "-",
    foto: require("../Img/kacangtanah.webp"),
  },
  {
    id: 2,
    nama_produk: "Kacang Hijau",
    product_name: "Mung Beans",
    ukuran: "3.25mm - 4mm",
    size: "3.25mm - 4mm",
    berat: "25kg",
    bungkusan: "PP Bag",
    packaging: "PP Bag",
    asal: "Myanmar, Indonesia",
    merek: "Mantul",
    foto: require("../Img/kacanghijau.webp")
  },
  {
    id: 3,
    nama_produk: "Cabe Kering",
    product_name: "Dried Chili",
    ukuran: "Gembung, Keriting & Byadagi",
    size: "Bloat, Curly & Byadagi",
    berat: "10kg",
    bungkusan: "Kain Goni",
    packaging: "Burlap Bag",
    asal: "India",
    merek: "777, Mantul, HK",
    foto: require("../Img/cabe.webp")
  },
  {
    id: 4,
    nama_produk: "Biji Wijen",
    product_name: "Sesame Seeds",
    ukuran: "-",
    size: "-",
    berat: "25kg",
    bungkusan: "Paper Bag",
    packaging: "Paper Bag",
    asal: "India",
    merek: "HK",
    foto: require("../Img/wijen.webp")
  },
  {
    id: 5,
    nama_produk: "Kemiri",
    product_name: "Candlenut",
    ukuran: "Bulat & Pecah",
    size: "Whole & Broken",
    berat: "10kg, 25kg",
    bungkusan: "PP Bag",
    packaging: "PP Bag",
    asal: "Indonesia",
    merek: "Mantul",
    foto: require("../Img/kemiri.webp")
  },
  {
    id: 6,
    nama_produk: "Kacang Kedelai",
    product_name: "Soybeans",
    ukuran: "-",
    size: "-",
    berat: "50kg",
    bungkusan: "PP Bag",
    packaging: "PP Bag",
    asal: "USA, Canada",
    merek: "Kunci, Angsa",
    foto: require("../Img/kedelai.webp")
  },
  {
    id: 7,
    nama_produk: "Kacang Kupas",
    product_name: "Blanched Peanuts",
    ukuran: "41/51 & 51/61 & 61/71",
    size: "41/51 & 51/61 & 61/71",
    berat: "2 × 12.5kg",
    bungkusan: "Karton",
    packaging: "Carton",
    asal: "China",
    merek: "Angsa",
    foto: require("../Img/kacangkupas.webp")
  },
  {
    id: 8,
    nama_produk: "Jinten Kasar",
    product_name: "Fennel Seed",
    ukuran: "-",
    size: "-",
    berat: "25kg",
    bungkusan: "PP Bag",
    packaging: "PP Bag",
    asal: "India, Mesir",
    merek: "HK",
    foto: require("../Img/jinten.webp")
  },
  {
    id: 9,
    nama_produk: "Jinten Halus",
    product_name: "Cumin Seed",
    ukuran: "-",
    size: "-",
    berat: "25kg",
    bungkusan: "PP Bag",
    packaging: "PP Bag",
    asal: "India, Mesir",
    merek: "HK",
    foto: require("../Img/jintenhalus.webp")
  },
  {
    id: 10,
    nama_produk: "Ketumbar",
    product_name: "Coriander",
    ukuran: "Bulat & Pecah",
    size: "Whole & Broken",
    berat: "25kg",
    bungkusan: "PP Bag",
    packaging: "PP Bag",
    asal: "Bulgaria, India",
    merek: "HK",
    foto: require("../Img/ketumbar.webp")
  },
  {
    id: 11,
    nama_produk: "Milet Putih",
    product_name: "White Milet",
    ukuran: "-",
    size: "-",
    berat: "25kg",
    bungkusan: "PP Bag",
    packaging: "PP Bag",
    asal: "USA, Canada, Ukraine",
    merek: "Mantul",
    foto: require("../Img/miletputih.webp")
  },
  {
    id: 12,
    nama_produk: "Milet Merah",
    product_name: "Red Millet",
    ukuran: "-",
    size: "-",
    berat: "25kg",
    bungkusan: "PP Bag",
    packaging: "PP Bag",
    asal: "USA, Canada, Ukraine",
    merek: "Mantul",
    foto: require("../Img/miletmerah.webp")
  },
  {
    id: 13,
    nama_produk: "Biji Kenari",
    product_name: "Canary Seeds",
    ukuran: "-",
    size: "-",
    berat: "25kg",
    bungkusan: "PP Bag",
    packaging: "PP Bag",
    asal: "USA, Canada",
    merek: "Mantul",
    foto: require("../Img/bijikenari.webp")
  },
  {
    id: 14,
    nama_produk: "Jawawut",
    product_name: "Jawawut Millet",
    ukuran: "-",
    size: "-",
    berat: "25kg",
    bungkusan: "PP Bag",
    packaging: "PP Bag",
    asal: "China",
    merek: "Mantul",
    foto: require("../Img/miletputih.webp")
  },
  {
    id: 15,
    nama_produk: "Beras Ketan Hitam",
    product_name: "Black Glutinous Rice",
    ukuran: "-",
    size: "-",
    berat: "25kg",
    bungkusan: "PP Bag",
    packaging: "PP Bag",
    asal: "Indonesia",
    merek: "-",
    foto: require("../Img/ketanhitam.webp")
  },
  {
    id: 16,
    nama_produk: "Beras Ketan Putih",
    product_name: "White Glutinous Rice",
    ukuran: "-",
    size: "-",
    berat: "25kg",
    bungkusan: "PP Bag",
    packaging: "PP Bag",
    asal: "Indonesia, Vietnam, Thailand",
    merek: "-",
    foto: require("../Img/ketanputih.webp"),
  },
];
export default product_list;
