import React, { useState } from "react";
import {
  GoogleMap,
  InfoWindow,
  LoadScript,
  Marker,
} from "@react-google-maps/api";
import "./Maps.css";

function Maps2() {
  const [Open, setOpen] = useState(false);

  const handleToggleOpen = () => {
    setOpen(true);
  };

  const handleToggleClose = () => {
    setOpen(false);
  };
  const containerStyle = {
    width: "100%",
    height: "40vh",
  };

  const center = {
    lat: 3.5885821,
    lng: 98.6721657,
  };
  return (
    <div className="Maps">
      <LoadScript googleMapsApiKey="AIzaSyDBOAE4dLApdJrmGi_b05HycXdwkKjp2cM">
        <GoogleMap
          mapContainerStyle={containerStyle}
          center={center}
          zoom={15}
          mapContainerClassName="map-container"
        >
        <Marker
          title="PT. Agri Tunas Makmur"
          position={center}
          onLoad={handleToggleOpen}
          onClick={handleToggleOpen}
        >
          {Open && (
            <InfoWindow
              onCloseClick={handleToggleClose}
            >
              <div className="Maps-Info">
                <span>PT. Agri Tunas Makmur</span>
                <p>Jl. Candi Prambanan No.2BB, </p>
                <p> Petisah Tengah, </p>
                <p>Kec. Medan Petisah, </p>
                <p> Kota Medan, </p>
                <p>Sumatera Utara 20236</p>
                <a
                  href="https://goo.gl/maps/67FfykK9BiLHr3sQ6"
                  target="_blank"
                  rel="noreferrer"
                >
                  View on Google Maps
                </a>
              </div>
            </InfoWindow>
          )}
        </Marker>
        </GoogleMap>
      </LoadScript>
    </div>
  );
}

export default React.memo(Maps2);
