import React, { useRef, useState } from "react";
import emailjs from "@emailjs/browser";
import { FiPhoneCall, FiMail, FiRepeat, FiInstagram } from "react-icons/fi";
import { GoLocation } from "react-icons/go";
import { BiTimeFive } from "react-icons/bi";
import { BsWhatsapp } from "react-icons/bs";
import "./Contact.css";
import Maps2 from "../Maps/Maps2";
import { Helmet } from "react-helmet-async";
const Contact = () => {
  const form = useRef();
  const [done, setDone] = useState(false);
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_5dm6pdl",
        "template_1uip7om",
        form.current,
        "A0q2Hz-oGq6y4_j0b"
      )
      .then(
        (result) => {
          console.log(result.text);
          setDone(true);
          e.target.reset();
        },
        (error) => {
          console.log(error.text);
        }
      );
  };
  return (
    <div className="Contact">
      <Helmet>
        <title>Hubungi Kami - PT Agri Tunas Makmur</title>
        <meta
          name="description"
          content="Kantor Pusat · Jl. Candi Prambanan No.2BB Medan
          Email · agritunasmakmur@gmail.com
          No Telp · 061 455 3185
          No HP · +62 853 7342 4998
          "
        />
        <link
          rel="canonical"
          href="https://www.agritunasmakmur.com/hubungi-kami/"
        />
      </Helmet>
      <div className="Contact-map">
        <Maps2 />
      </div>
      <div className="Contact-Main">
        <div className="Contact-h1">
          <div className="Contact-info">
            <h1>Hubungi Kami</h1>
            <a
              href="tel:0614553185"
              style={{ textDecoration: "none" }}
              target="_blank"
              rel="noreferrer"
            >
              <FiPhoneCall /> 061 455 3185
            </a>

            <a
              style={{ textDecoration: "none" }}
              href="https://goo.gl/maps/67FfykK9BiLHr3sQ6"
              target="_blank"
              rel="noreferrer"
            >
              <GoLocation /> Jl. Candi Prambanan No.2BB Medan
            </a>
            <p>
              <BiTimeFive /> 09:00 – 17:00 (Senin – Sabtu)
            </p>
            <p>
              <FiMail /> agritunasmakmur@gmail.com
            </p>
            <a
              style={{ textDecoration: "none" }}
              href="https://www.instagram.com/agritunasmakmur/"
              target="_blank"
              rel="noreferrer"
            >
              <FiInstagram /> agritunasmakmur
            </a>
            <a
              style={{ textDecoration: "none" }}
              href="https://wa.me/6285373424998?text=Saya%20tertarik%20dengan%20produk%20Anda%20yang%20dijual"
              target="_blank"
              rel="noreferrer"
            >
              <BsWhatsapp /> +62 853 7342 4998
            </a>
          </div>
        </div>
        <div className="Contact-h2">
          <form
            className={done ? "Contact-hide" : ""}
            ref={form}
            onSubmit={sendEmail}
          >
            <div className="row">
              <label>Nama</label>
              <input className="user" type="text" name="user_name" required />
            </div>
            <div className="row">
              <label>Email</label>
              <input className="user" type="email" name="user_email" required />
            </div>
            <div className="row">
              <label>Nomor HP</label>
              <input className="user" type="tel" name="user_phone" required />
            </div>
            <div className="row">
              <label>Pesan</label>
              <textarea className="user" name="message" required />
            </div>
            <input className="button" type="submit" value="Kirim Email" />
          </form>
          <div className="Contact-done">
            <h2>{done && "Terima kasih telah menghubungi kami!"}</h2>
            {done && <FiRepeat onClick={() => setDone(false)} />}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
