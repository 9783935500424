import React from "react";
import Footer from "../Footer/Footer";
import Navigator from "../Navbar/Navigator";
import Home from "./Home";
import { Routes, Route } from "react-router-dom";
import Contact from "../Contact/Contact";
import ProductList from "../Product/ProductList";
import NotFound from "./NotFound";
import Products from "../Product/Products";
import Tentang from "../Tentang/Tentang";
import { motion } from "framer-motion";
const Page = () => {
  return (
    <div>
      <Navigator />
      <motion.div
        animate={{ opacity: 1 }}
        initial={{ opacity: 0 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.4 }}
      >
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/hubungi-kami" element={<Contact />} />
          <Route path="/daftar-produk" element={<ProductList />} />
          <Route path="/produk/:id" element={<Products />} />
          <Route path="/tentang-kami" element={<Tentang />} />
          <Route path="/*" element={<NotFound />} />
        </Routes>
      </motion.div>
      <Footer />
    </div>
  );
};

export default Page;
