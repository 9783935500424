import { createStore, combineReducers } from "redux";
import { productReducer } from "./Reducers/productReducer";

const reducer = combineReducers({
  allProducts: productReducer,
});
const initialState = {};
const store = createStore(
  reducer,
  initialState
);

export default store;
