import React, { useEffect } from "react";
import { Navbar, Container, Nav } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "./Navigator.css";
import { US } from "country-flag-icons/react/3x2";
import { ID } from "country-flag-icons/react/3x2";
import logo from "../Img/logonobg.webp";
import { useState } from "react";

const Navigator = () => {
  const [show, setShow] = useState(true);
  const [YCoor, setYCoor] = useState(window.scrollY);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const controlNavbar = () => {
    if (window.scrollY > YCoor) {
      setShow(false);
      setYCoor(window.scrollY);
    } else {
      setShow(true);
      setYCoor(window.scrollY);
    }
  };
  useEffect(() => {
    window.addEventListener("scroll", controlNavbar);
    return () => {
      window.removeEventListener("scroll", controlNavbar);
    };
  }, [YCoor, controlNavbar]);

  return (
    <div className="Nav">
      <Navbar
        className={`Nav-h ${!show && "Nav-hidden"}`}
        fixed="top"
        collapseOnSelect
        expand="lg"
      >
        <Container>
          <Navbar.Brand href="/" className="Nav-title">
            <div className="Nav-logo">
              <img src={logo} alt="" />
              <h2>Agri Tunas Makmur</h2>
            </div>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="me-auto">
              <Nav.Link href="/">Beranda</Nav.Link>
              <Nav.Link href="/tentang-kami">Tentang Kami</Nav.Link>
              <Nav.Link href="/daftar-produk">Produk Kami</Nav.Link>
            </Nav>
            <Nav>
              <Nav.Link href="/hubungi-kami">Hubungi Kami</Nav.Link>
            </Nav>
            <Nav className="Nav-lang">
              <Nav.Link href="/">
                <ID /> Indonesia
              </Nav.Link>
              <Nav.Link href="/eng">
                <US /> English
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  );
};

export default Navigator;
