import { FiPhoneCall, FiMail, FiInstagram } from "react-icons/fi";
import { GoLocation } from "react-icons/go";
import { BiTimeFive } from "react-icons/bi";
import { BsWhatsapp } from "react-icons/bs";
import "./Footer.css";
import React from "react";
import product_list from "../Product/ProductArray.jsx";
function FooterEng() {
  const d = new Date();
  let year = d.getFullYear();
  const listProducts = product_list.map((product) => (
    <li key={product.id}>
      <a
        href={`/eng/products/${product.product_name
          .toLowerCase()
          .split(" ")
          .join("-")}`}
        style={{ textDecoration: "none" }}
      >
        {product.product_name}
      </a>
    </li>
  ));
  return (
    <div className="Footer">
      <div className="Footer-h">
        <div className="Footer-info">
          <h3>Agri Tunas Makmur</h3>
          <p>
            PT Agri Tunas Makmur is a company engaged in agriculture. Our
            company imports more agricultural materials from 15 countries and
            sell it on a large scale.
          </p>
        </div>
        <div className="Footer-product">
          <h3>Products</h3>
          <ul>{listProducts}</ul>
        </div>
        <div className="Footer-contact">
          <h3>Contact Us</h3>
          <a
            href="tel:0614553185"
            style={{ textDecoration: "none" }}
            target="_blank"
            rel="noreferrer"
          >
            <p>
              <FiPhoneCall /> 061 415 2898
            </p>
          </a>
          <a
            href="tel:081260869388"
            style={{ textDecoration: "none" }}
            target="_blank"
            rel="noreferrer"
          >
            <p>
              <FiPhoneCall /> +62 853 7342 4998
            </p>
          </a>
          <a
            style={{ textDecoration: "none" }}
            href="https://goo.gl/maps/67FfykK9BiLHr3sQ6"
            target="_blank"
            rel="noreferrer"
          >
            <p>
              <GoLocation /> Jl. Candi Prambanan No.2BB Medan
            </p>
          </a>
          <p>
            <BiTimeFive /> 09:00 – 17:00 (Monday – Saturday)
          </p>
          <a
            style={{ textDecoration: "none" }}
            href="https://www.instagram.com/agritunasmakmur/"
            target="_blank"
            rel="noreferrer"
          >
            <p>
              <FiInstagram /> agritunasmakmur
            </p>
          </a>
          <a href="/eng/contact-us" style={{ textDecoration: "none" }}>
            <p>
              <FiMail /> agritunasmakmur@gmail.com
            </p>
          </a>
        </div>
        <div className="Footer-fastresp">
          <h3>Fast Response</h3>
          <p>
            <a
              style={{ textDecoration: "none" }}
              href="https://wa.me/6285373424998?text=I%20am%20interested%20with%20your%20Product"
              target="_blank"
              rel="noreferrer"
            >
              <BsWhatsapp /> +62 853 7342 4998
            </a>
          </p>
        </div>
      </div>
      <div className="Footer-f">
        Copyright © {year} Agri Tunas Makmur. All Rights Reserved.{" "}
      </div>
    </div>
  );
}

export default FooterEng;
