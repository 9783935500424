import React, { useEffect, useState } from "react";
import "./Home.css";
import Banner from "../Banner/Banner";
import CS from "../Img/customer.webp";
import Imp from "../Img/import.webp";
import logo2 from "../Img/logonobg2.webp";
import quality from "../Img/quality.webp";
import pengiriman from "../Img/delivery.webp";
import video from "../Img/video.mp4";
import { Button } from "react-bootstrap";
import { MoonLoader } from "react-spinners";
function Home() {
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
  }, []);
  return (
    <div>
      <div className={loading ? "PageLoading" : "PageNone"}>
        <MoonLoader color="#007057" loading={loading} />
      </div>
      <div className={loading ? "PageNone" : "Page"}>
        <Banner />
        <div className="Home">
          <div className="Home-A">
            <div className="Home-AB">
              <div className="Home-A1"><h1>"PT Agri Tunas Makmur</h1>
                <p>
                   adalah perusahaan yang bergerak dalam
                  bidang jual beli bahan pertanian. PT Agri Tunas Makmur telah
                  berdiri sejak tahun 2015 di kota Medan, Sumatera utara.
                  Perusahaan kami mengimpor bahan pertanian dari lebih dari 15
                  negara dan menjualnya dalam skala besar kepada pembeli di
                  pasar Indonesia."
                </p>
              </div>
              <div className="Home-A2">
                <div className="Home-vcontainer">
                  <video
                    className="Home-video"
                    src={video}
                    width="640"
                    height="360"
                    muted
                    loop
                    autoPlay
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="Home-SwiperC">
            <div className="Home-AB">
              <div className="Home-A3">
                <img src={logo2} alt="agritunasmakmur" />
                <h2>Agri Tunas Makmur</h2>
                <p>
                  "PT Agri Tunas Makmur adalah solusi tepercaya untuk memenuhi
                  semua kebutuhan usaha anda, berlokasi di Medan, Sumatera
                  Utara."
                </p>
              </div>
              <div className="Home-A3">
                <img src={quality} alt="quality" />
                <h2>Kualitas</h2>
                <p>
                  "Ribuan produk segar terbaik yang dapat disesuaikan dengan
                  kebutuhan usaha anda."
                </p>
              </div>
              <div className="Home-A3">
                <img src={pengiriman} alt="pengiriman" />
                <h2>Pengiriman</h2>
                <p>
                  "Serta didukung oleh ekosistem logistik dan penyimpanan yang
                  mutakhir."
                </p>
              </div>
            </div>
          </div>
          <div className="Home-B">
            <div className="Home-B2">
              <div className="Home-B2W">
                <h3>Produk</h3>
                <img src={Imp} alt="" />
                <p>
                  Kami memiliki banyak macam produk yang diimpor lebih dari 15
                  negara.
                </p>
                <Button
                  className="Home-BButton"
                  href="/daftar-produk"
                  variant="success"
                >
                  Produk Kami
                </Button>
              </div>
            </div>
            <div className="Home-B2">
              <div className="Home-B2W">
                <h3>Layanan</h3>
                <img src={CS} alt="" onLoad={() => setLoading(false)} />
                <p>
                  Jika ada yang ingin ditanyakan bisa langsung kontak kami
                  disini.
                </p>
                <Button
                  className="Home-BButton"
                  href="/hubungi-kami"
                  variant="success"
                >
                  Hubungi Kami
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
