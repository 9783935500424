import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { searchProduct } from "../Redux/Actions/productActions";
import "./ProductList.css";
import Empty from "../Img/empty.webp";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import { Helmet } from "react-helmet-async";
function ProductList() {
  const [queries, setQuery] = useState("");
  const dispatch = useDispatch();
  const handleQuery = (e) => {
    setQuery(e.target.value);
    dispatch(searchProduct(e.target.value.toLowerCase()));
  };
  const { productsList: list, query } = useSelector(
    (state) => state.allProducts
  );
  const filteredList = list.filter((item) =>
    item.nama_produk.toLowerCase().includes(query)
  );

  return (
    <div className="Product">
      <Helmet>
        <title>Daftar Produk PT. Agri Tunas Makmur</title>
        <meta
          name="description"
          content="Daftar Produk PT. Agri Tunas Makmur"
        />
        <link rel="canonical" href="https://www.agritunasmakmur.com/daftar-produk/" />
      </Helmet>
      <h1>Daftar Produk</h1>
      <div className="Product-Search">
        <input
          type="text"
          value={queries}
          placeholder="Cari Produk"
          onChange={(e) => handleQuery(e)}
        />
      </div>
      <div className={filteredList.length ? "Product-List" : "Product-Main"}>
        {filteredList.length ? (
          filteredList.map((product) => {
            return (
              <Link
                to={`/produk/${product.nama_produk
                  .toLowerCase()
                  .split(" ")
                  .join("-")}`}
                style={{ textDecoration: "none", color: "#191919" }}
                key={product.id}
              >
                <motion.div
                  initial={{ opacity: 0, scale: 0.5 }}
                  animate={{ opacity: 1, scale: 1 }}
                  transition={{ duration: 0.3 }}
                >
                  <div className="card">
                    <div className="card-img">
                      <img
                        src={product.foto}
                        alt={"PT Agri Tunas Makmur - Supplier " + product.nama_produk + " Medan"}
                        id={product.id}
                      />
                    </div>
                    <div className="card-header">
                      <h2>{product.nama_produk}</h2>
                    </div>
                  </div>
                </motion.div>
              </Link>
            );
          })
        ) : (
          <div className="Product-Empty">
            <span>Produk tidak ditemukan!</span>
            <img src={Empty} alt="" />
            <a style={{ textDecoration: "none" }} href="http://www.freepik.com">
              Designed by slidesgo / Freepik
            </a>
            <br />
          </div>
        )}
      </div>
    </div>
  );
}

export default ProductList;
