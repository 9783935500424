import product_list from "../../Product/ProductArray";
import { SEARCH_PRODUCT, SET_PRODUCTS } from "../Contants/actionTypes";
const initialState = {
  productsList: product_list,
  query: "",
};
export const productReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_PRODUCTS:
      return {
        ...state,
      };
      case SEARCH_PRODUCT: {
        const { query } = action;
        return {
          ...state,
          query,
        };
      }
    default:
      return state;
    
  }

};
