import React, { useRef, useState } from "react";
import emailjs from "@emailjs/browser";
import { FiPhoneCall, FiMail, FiRepeat, FiInstagram } from "react-icons/fi";
import { GoLocation } from "react-icons/go";
import { BiTimeFive } from "react-icons/bi";
import { BsWhatsapp } from "react-icons/bs";
import "./Contact.css";
import Maps2 from "../Maps/Maps2";
import { Helmet } from "react-helmet-async";
function ContactEng() {
  const form = useRef();
  const [done, setDone] = useState(false);
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_5dm6pdl",
        "template_1uip7om",
        form.current,
        "A0q2Hz-oGq6y4_j0b"
      )
      .then(
        (result) => {
          console.log(result.text);
          setDone(true);
          e.target.reset();
        },
        (error) => {
          console.log(error.text);
        }
      );
  };
  return (
    <div className="Contact">
      <Helmet>
        <title>Contact Us - PT Agri Tunas Makmur Contact Info</title>
        <meta
          name="description"
          content="Main Office · Jl. Candi Prambanan No.2BB Medan |
        Email · agritunasmakmur@gmail.com |
        Phone · 061 455 3185 |
        Instagram · agritunasmakmur |
        WhatsApp · +62 853 7342 4998"
        />
        <link
          rel="canonical"
          href="https://www.agritunasmakmur.com/eng/contact-us/"
        />
        <meta
          property="og:url"
          content="https://www.agritunasmakmur.com/eng/contact-us/"
        />
        <meta
          property="twitter:url"
          content="https://www.agritunasmakmur.com/eng/contact-us/"
        />
        <meta
          property="og:title"
          content="Contact Us - PT Agri Tunas Makmur Contact Info"
        />
        <meta
          property="og:description"
          content="Main Office · Jl. Candi Prambanan No.2BB Medan |
          Email · agritunasmakmur@gmail.com |
          Phone · 061 455 3185 |
          Instagram · agritunasmakmur |
          WhatsApp · +62 853 7342 4998"
        />
        <meta
          property="twitter:title"
          content="Contact Us - PT Agri Tunas Makmur Contact Info"
        />
        <meta
          property="twitter:description"
          content="Main Office · Jl. Candi Prambanan No.2BB Medan |
          Email · agritunasmakmur@gmail.com |
          Phone · 061 455 3185 |
          Instagram · agritunasmakmur |
          WhatsApp · +62 853 7342 4998"
        />
      </Helmet>
      <div className="Contact-map">
        <Maps2 />
      </div>
      <div className="Contact-Main">
        <div className="Contact-h1">
          <div className="Contact-info">
            <h1>Contact Us</h1>
            <a
              href="tel:0614553185"
              style={{ textDecoration: "none" }}
              target="_blank"
              rel="noreferrer"
            >
              <FiPhoneCall /> 061 455 3185
            </a>

            <a
              style={{ textDecoration: "none" }}
              href="https://goo.gl/maps/67FfykK9BiLHr3sQ6"
              target="_blank"
              rel="noreferrer"
            >
              <GoLocation /> Jl. Candi Prambanan No.2BB Medan
            </a>
            <p>
              <BiTimeFive /> 09:00 – 17:00 (Monday – Saturday)
            </p>
            <p>
              <FiMail /> agritunasmakmur@gmail.com
            </p>
            <a
              style={{ textDecoration: "none" }}
              href="https://www.instagram.com/agritunasmakmur/"
              target="_blank"
              rel="noreferrer"
            >
              <FiInstagram /> agritunasmakmur
            </a>
            <a
              style={{ textDecoration: "none" }}
              href="https://wa.me/6285373424998?text=I%20am%20interested%20with%20your%20Product"
              target="_blank"
              rel="noreferrer"
            >
              <BsWhatsapp /> +62 853 7342 4998
            </a>
          </div>
        </div>
        <div className="Contact-h2">
          <form
            className={done ? "Contact-hide" : ""}
            ref={form}
            onSubmit={sendEmail}
          >
            <div className="row">
              <label>Name</label>
              <input className="user" type="text" name="user_name" required />
            </div>
            <div className="row">
              <label>Email</label>
              <input className="user" type="email" name="user_email" required />
            </div>
            <div className="row">
              <label>Phone Number</label>
              <input className="user" type="tel" name="user_phone" required />
            </div>
            <div className="row">
              <label>Message</label>
              <textarea className="user" name="message" required />
            </div>
            <input className="button" type="submit" value="Send Email" />
          </form>
          <div className="Contact-done">
            <h2>{done && "Thankyou for Contacting Us!"}</h2>
            {done && <FiRepeat onClick={() => setDone(false)} />}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContactEng;
