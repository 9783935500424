import React from "react";
import "./Tentang.css";
import Visi from "../Img/vision.webp";
import Mission from "../Img/mission.webp";
import Values from "../Img/values.webp";
import mantul from "../Img/mantul.webp";
import angsa from "../Img/angsa.webp";
import kunci from "../Img/kunci.webp";
import m777 from "../Img/777.webp";
import HK from "../Img/HK.webp";
import logo from "../Img/logo200.webp";
import kacangkupas from "../Img/kacangkupastangan.webp";
import video from "../Img/video2.mp4";
import { Helmet } from "react-helmet-async";
function Tentang() {
  return (
    <div className="Tentang">
      <Helmet>
        <title>Tentang PT Agri Tunas Makmur</title>
        <meta
          name="description"
          content="PT Agri Tunas Makmur adalah perusahaan yang bergerak dalam bidang
          jual beli bahan pertanian. PT Agri Tunas Makmur telah berdiri sejak
          tahun 2015 di kota Medan, Sumatera utara. Perusahaan kami mengimpor
          bahan pertanian dari lebih dari 15 negara dan menjualnya dalam skala
          besar kepada pembeli di pasar indonesia."
        />
        <link
          rel="canonical"
          href="https://www.agritunasmakmur.com/tentang-kami/"
        />
      </Helmet>
      <div className="Tentang-L">
        <img src={logo} alt="" />
        <h2>PT Agri Tunas Makmur</h2>
      </div>
      <div className="Tentang-H">
        <div className="Tentang-S">
          <h1>Sejarah Kami</h1>
          <p>
            PT Agri Tunas Makmur adalah perusahaan yang mengkhususkan diri dalam
            jual beli bahan pertanian. Kami berdiri sejak tahun 2015 di kota
            Medan, Sumatera Utara, dan telah berhasil mengimpor bahan pertanian
            dari lebih dari 15 negara untuk dijual di pasar Indonesia.
            <br />
            <br />
            Dengan tata kelola perusahaan yang baik dan kepercayaan pelanggan
            yang tinggi, Agri Tunas Makmur telah berkembang dengan pesat dari
            toko kecil di pasar pada tahun 90an. Kami terus berusaha untuk
            memberikan yang terbaik bagi pelanggan kami.
          </p>
        </div>
        <div className="Tentang-banner">
          <img src={kacangkupas} alt="kacang kupas" />
          <video src={video} autoPlay loop muted />
        </div>
        <div className="Tentang-VMV">
          <div className="Tentang-Visi">
            <img src={Visi} alt="" />
            <h2>Visi</h2>
            <p>
              Menjadi perusahaan impor ekspor dengan reputasi unggul dan
              terpercaya untuk pembeli kami.
            </p>
          </div>
          <div className="Tentang-Misi">
            <img src={Mission} alt="" />

            <h2>Misi</h2>
            <p>Memberikan produk dengan kualitas terbaik untuk pembeli kami.</p>
          </div>
          <div className="Tentang-Value">
            <img src={Values} alt="" />
            <h2>Nilai Perusahaan</h2>
            <p>
              Nilai yang kami junjung tinggi adalah Kejujuran, Kepercayaan, dan
              Integritas.
            </p>
          </div>
        </div>
        <div className="Tentang-M">
          <h2>Merek Kami</h2>
          <div className="Tentang-Img">
            <img src={kunci} alt="" />
            <img src={angsa} alt="" />
            <img src={mantul} alt="" />
            <img src={m777} alt="" />
            <img src={HK} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Tentang;
